export default {
  rulesText: {
    required: "Обязательно для заполнения",
    noSpace: "Запрещенный символ",
    min15: "Не менее 15 символов",
    max255: "Не более 255 символов",
    onlyNumber: "Только цифры",
    isIp: "Некорректный IP",
    isMac: "Некорректный MAC",
    minOneDns: "Введите хотя бы один DNS",
    isMask: "Некорректная Маска",
    from_min_to_max: "От {0} до {1}",
  },

  navBar: {
    index: "Главная",
    dashboard: "Дашбоард",
    points: "ЭЗС",
    users: "Пользователи",
    billing: "Платежи",
    editDevice: "Изменение устройства",
    history: "История",
    point_config: "Конфигурация заправки",
    tasks: "Задачи",
    tariff: "Тарифы",
    edit_task: "Редактировать",
    logout: "Выход",
    user_create: "Добавить пользователя",
    point_create: "Добавить заправку",
    client_create: "Добавить клиента",
    list: "Список",
    points_list: "ЭЗС",
    list_users: "Пользователи",
    list_clients: "Клиенты",
    list_charging: "Зарядные сессии",
    list_reports: "Отчеты",
    list_payment: "Платежи",
    list_tariff: "Тарифы",
    list_sms: "SMS",
    tariff_create: "Добавить тариф",
    tariff_info: "Информация о тарифе",
    clients: "Клиенты",
    charging: "Зарядки",
    reports: "Отчеты",
    report_create: "Отчет о зарядках",
    payment: "Платежи",
    point_info: "О станции",
    charging_info: "Зарядная сессия",
    list_partners: "Партнеры",
    partners: "Партнеры",
    client_info: "Клиент",
    list_rfcard: "Карты",
  },

  loginPage: {
    deviceName: "CP management",
    username: "Имя пользователя",
    password: "Пароль",
    error401: "Неправильное имя пользователя или пароль",
    login: "Вход",
    alert: "Неправильное имя пользователя или пароль",
  },

  networkPage: {
    lan1: "Lan1",
    lan2: "Lan2",
    inactive: "Интерфейс неактивен",
    saveButton: "Сохранить",
    ipConfig: {
      mac: "MAC",
      macHint: "Укажите MAC адрес",
      mode: "Режим работы",
      modeStatic: "Статический IP",
      modeDHCP: "DHCP",
      ip: "IP адрес",
      ipHint: "например 192.168.1.2",
      mask: "Маска",
      maskHint: "например 255.255.255.0",
      gateway: "Шлюз",
      gatewayHint: "192.168.1.1",
      dns1: "DNS 1",
      dns1Hint: "192.168.1.1",
      dns2: "DNS 2",
      dns2Hint: "192.168.1.1",
    },
  },

  devicesPage: {
    slaveID: "Slave ID",
    mqtt: "Топик MQTT",
    protocol: "Протокол",
    interval: "Интервал опроса (ms)",
    port: "Порт",
    history: "История",
    edit: "Редактировать",
    addButton: "Добавить устройство",
  },

  addDevicePage: {
    name: "Добавить устройство",
    port: "Порт",
    portList: [
      "RS-485 #1",
      "RS-485 #2",
      "RS-232 #1",
      "RS-232 #2",
      "LAN1",
      "LAN2",
      "1-Wire",
    ],
    deviceType: "Устройство",
    deviceCustom: "Другое",
    slaveID: "Slave ID",
    slaveIDHint: "Десятичный (например 123)",
    mqttName: "Имя MQTT",
    mqttNameHint: "Например: device1",
    password: "Пароль",
    passwordHint: "Введите пароль",
    registers: "Регистры",
    selectAll: "Выбрать все",
    deselectAll: "Убрать все",
    interval: "Интервал опроса (ms)",
    intervalHint: "Задайте интервал опроса",
    history: "Сохранять историю",
    historyList: [
      "Отключено",
      "1 мин",
      "5 мин",
      "10 мин",
      "15 мин",
      "30 мин",
      "1 ч",
      "6 ч",
      "12 ч",
      "24 ч",
    ],
    cancelButton: "Отмена",
    saveButton: "Добавить<br>устройство",
    alert: "Проверьте правильность ввода",
  },

  editDevicePage: {
    name: "Изменение устройства",
    saveButton: "Сохранить<br>изменения",
    deleteButton: "Удалить<br>устройство",
    confirm: {
      title: "Вы действительно хотите<br>удалить это устройство?",
      cancelButton: "Отмена",
      confirmButton: "Удалить",
    },
  },

  historyPage: {
    device: "Устройство",
    channel: "Канал",
    period: "Период",
    setDate: "OK",
    cancelSetDate: "Отмена",
    export: "Экспорт в CSV",
    loading: "Загрузка",
    chart: {
      title: "История устройства",
      yAxisTitle: "Значения",
      xAxisTitle: "Дата",
    },
    intervals: {
      m_1: "Последняя минута",
      m_10: "Последние 10 минут",
      m_30: "Последние 30 минут",
      h_1: "Последний час",
      h_3: "Последние 3 часа",
      h_5: "Последние 5 часов",
      h_12: "Последние 12 часов",
      h_24: "Последние 24 часа",
      d_3: "Последние 3 дня",
      d_7: "Последние 7 дней",
      custom: "Настраиваемый",
    },
  },

  serialPortConfig: {
    inactive: "Этот порт сейчас неактивен",
    ports: ["RS-485 #1", "RS-485 #2", "RS-232 #1", "RS-232 #2"],
    baud: "Скорость порта",
    baudList: [
      "1200",
      "2400",
      "4800",
      "9600",
      "19200",
      "38400",
      "57600",
      "115200",
    ],
    databit: "Биты данных",
    databitList: ["7", "8"],
    parity: "Четность",
    parityList: ["Нет", "Четный", "Нечетный"],
    stopbit: "Стоповые биты",
    stopbitList: ["1", "2"],
    saveButton: "Сохранить",
    alert: "Упс",
    success: "Ура!",
  },

  registersPage: {
    device: "Устройство",
    showTable: "Показать таблицу",
    hideTable: "Скрыть таблицу",
    name: "Регистр",
    value: "Значение",
    newValue: "Новое значение",
    showChart: "Показать на графике",
    add_chart: "Добавить график",
    chart: {
      none: "Нет",
      title: "График",
    },
  },

  servoPage: {
    value: "Поворот",
    step: "Шаг",
  },

  charts: {
    zoom: "Приближение",
    m_10: "10м",
    h_1: "1ч",
    h_3: "3ч",
    h_6: "6ч",
    h_24: "24ч",
    all: "Всё",
  },
};
