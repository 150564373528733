import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    title: "dashboard",
    component: () => import("@/views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
    ],
  },
  {
    path: "/points",
    title: "points",
    component: () => import("@/views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "points_list",
        component: () => import("../views/points/ListPoints"),
        meta: {
          auth: {
            roles: ['superadmin', 'manager']
          },
          layout: "default",
        },
      },
      {
        path: "create",
        name: "point_create",
        hide: true,
        component: () => import("../views/points/PointCreate"),
        meta: {
          auth: {
            roles: ['superadmin']
          },
          layout: "default",
        },
      },
      {
        path: ":point_id/info",
        name: "point_info",
        hide: true,
        component: () => import("../views/points/PointInfo"),
        meta: {
          auth: {
            roles: ['superadmin', 'manager']
          },
          layout: "default",
        },
      },
      {
        path: ":point_id/config",
        name: "point_config",
        hide: true,
        component: () => import("../views/points/CPconfig"),
        meta: {
          auth: {
            roles: ['superadmin']
          },
          layout: "default",
        },
      },
      {
        path: ":point_id/edit",
        hide: true,
        name: "point_edit",
        component: () => import("../views/points/PointCreate"),
        meta: {
          auth: {
            roles: ['superadmin']
          },
          layout: "default",
        },
      },
    ],
  },
  {
    path: "/charging",
    title: "charging",
    component: () => import("../views/charging/Charging"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_charging",
        component: () => import("../views/charging/ChargingList"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
      {
        path: ":charge_id",
        name: "charging_info",
        hide: true,
        component: () => import("../views/charging/ChargingInfo"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
    ],
  },
  {
    path: "/tariff",
    title: "tariff",
    component: () => import("../views/Index"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_tariff",
        component: () => import("../views/tariff/List"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
      {
        path: "create",
        hide: true,
        name: "tariff_create",
        component: () => import("../views/tariff/Create"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
      {
        path: ":tariff_id",
        name: "tariff_info",
        hide: true,
        component: () => import("../views/tariff/Config"),
        meta: {
          auth: {
            roles: ['superadmin']
          },
          layout: "default",
        },
      },
    ],
  },
  {
    path: "/client",
    title: "clients",
    component: () => import("../views/users/Users"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_clients",
        component: () => import("../views/clients/ListClients"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
      {
        path: ":client_id",
        hide: true,
        name: "client_info",
        component: () => import("../views/clients/ClientInfo.vue"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
    ],
  },
  {
    path: "/users",
    title: "users",
    component: () => import("../views/users/Users"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_users",
        component: () => import("../views/users/ListUsers"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
      {
        path: "create",
        name: "user_create",
        hide: true,
        component: () => import("../views/users/UserCreate"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
    ],
  },
  {
    path: "/reports",
    title: "reports",
    component: () => import("../views/report/Reports"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_reports",
        hide: true,
        component: () => import("../views/report/report_list"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
      {
        path: "create",
        name: "report_create",
        component: () => import("../views/report/create"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin', 'manager']
          },
        },
      },
    ],
  },
  {
    path: "/payment",
    title: "payment",
    component: () => import("../views/Billing"),
    meta: {
      layout: "default",
    },
    children: [
      {
        path: "",
        name: "list_payment",
        component: () => import("../views/billing/ListBilling"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
    ],
  },
  {
    path: "/partners",
    title: "partners",
    component: () => import("../views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_partners",
        component: () => import("../views/partner/Company"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
    ],
  },
  {
    path: "/sms",
    title: "sms",
    component: () => import("../views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_sms",
        component: () => import("../views/sms/Sms.vue"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
    ],
  },
  {
    path: "/rfcard",
    title: "rfcard",
    component: () => import("../views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_rfcard",
        component: () => import("../views/rfcard/RfcardList.vue"),
        meta: {
          layout: "default",
          auth: {
            roles: ['superadmin']
          },
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    hide: true,
    component: () => import("../views/Login.vue"),
    meta: {
      layout: "login",
    },
  },
  {
    hide: true,
    path: "*",
    redirect: "/dashboard",
  },
];

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default Vue.router;
