<template>
  <v-app-bar app :clipped-left="true" :clipped-right="true">
    <v-app-bar-nav-icon @click="show_drawer"></v-app-bar-nav-icon>
    <v-toolbar-title class="pa-0">{{ route_name }}</v-toolbar-title>
    <v-spacer></v-spacer>
<!--    <v-btn icon>-->
<!--      <v-icon>mdi-heart</v-icon>-->
<!--    </v-btn>-->

<!--    <div class="mr-2">-->
<!--      <v-btn icon>-->
<!--        <v-badge :content="1" :value="1" color="red" overlap>-->
<!--          <v-icon> mdi-bell-outline </v-icon>-->
<!--        </v-badge>-->
<!--      </v-btn>-->
<!--    </div>-->

    <v-btn icon elevation="1">
      <v-avatar size="36" color="green"
        ><span class="white--text">EV</span></v-avatar
      >
    </v-btn>
    <v-toolbar-items>
      <ThemeChanger />
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import ThemeChanger from "./ThemeChanger";

export default {
  name: "NavBar",
  components: {
    ThemeChanger
  },
  created() {},
  watch: {},
  computed: {
    route_name() {
      if (Object.keys(this.$route.params).length > 0) {
        const params = Object.keys(this.$route.params);
        return (
          this.$t("navBar." + this.$route.name) +
          " №" +
          this.$route.params[params[0]]
        );
      }
      return this.$t("navBar." + this.$route.name);
    },
  },
  methods: {
    show_drawer() {
      this.$store.dispatch("update_drawer", true);
    },
  },
};
</script>

<style scoped lang="scss"></style>
