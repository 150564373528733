import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: "ru",
    drawer: false,
    points: [],
    city: [],
    vendor: [],
    fw: [],
    points_count: 0,
    users: [],
    clients: [],
    clients_count: 0,
    charges: [],
    point_numbers: [],
    charges_count: 0,
    billing: [],
    billing_count: 0,
    tariffs: [],
    tariffs_count: 0,
    loading_users: false,
    loading_points: false,
    loading_clients: false,
    loading_charges: false,
    loading_billing: false,
    loading_tariffs: false,
    loading_charge_info: false,
    point_info: {
      address: "",
      available: false,
      city: null,
      created_at: "",
      firmware: null,
      id: 1,
      latitude: 55.76490006524094,
      longitude: 37.66129413574468,
      model: "",
      modem_iccid: null,
      modem_imsi: null,
      name: "",
      password: "",
      point_number: 0,
      reserved: false,
      serial: "",
      updated_at: null,
      vendor: "",
      images: [],
      evse: [{ power: "", connectors: [] }],
    },
    events: [],
    commands: [],
    point_tariffs: [],
    point_tariffs_loading: false,
    point_info_loading: false,
    dashboard: {
      charges: {
        total: 0,
        last_week: 0,
        today: 0,
        params: {
          labels: [],
          series: [
            {
              name: "Зарядки",
              data: [],
            },
          ],
        },
      },
      clients: {
        total: 0,
        last_week: 0,
        today: 0,
        params: {
          labels: [],
          series: [
            {
              name: "Клиенты",
              data: [],
            },
          ],
        },
      },
      points: {
        params: {
          series: [],
          labels: [],
        },
      },
    },
    latest_charges: [],
    charging_info: {
      id: 0,
      transaction_id: "",
      remote_start_id: null,
      power_amount: 0.0,
      payment_amount: 0.0,
      charge_power: 0.0,
      current_battery: 0.0,
      remaining_time: 0.0,
      reserved_evse: 0,
      reserved_connector: 0,
      reserve_time: null,
      reserve_end: null,
      created_at: "26/01/2022 11:57:32",
      updated_at: "26/01/2022 11:57:08",
      status: "",
      charge_point: {
        point_number: 0,
        address: "",
      },
    },
    charging_history: [],
    history_count: 0,
    partners_loading: false,
    partners_count: 0,
    partners: [{ members: [] }],
    company_dialog: false,
    partner_invite_dialog: false,
    partner_invite_company: null,
    loading_sms: false,
    sms_list: [],
    sms_count: 0,
    sms_dialog: false,
    add_point_dialog: false,
    client_loading: false,
    client_info: {},
    point_command_dialog: false,
    transactions: [],
    loading_rfcard: false,
    rfcard_count: 0,
    rfcard_list: [],
  },
  mutations: {
    UPDATE_DRAWER(state, payload) {
      state.drawer = payload;
    },
    UPDATE_POINTS(state, payload) {
      state.points_count = Number(payload.headers["total-count"]);
      state.city = payload.data.result.city;
      state.vendor = payload.data.result.vendor;
      state.fw = payload.data.result.fw;
      state.points = payload.data.result.points;
    },
    UPDATE_USERS(state, payload) {
      state.users = payload;
    },
    UPDATE_CLIENTS(state, payload) {
      state.clients_count = Number(payload.headers["total-count"]);
      state.clients = payload.data.result;
    },
    UPDATE_CHARGES(state, payload) {
      state.charges_count = Number(payload.headers["total-count"]);
      state.charges = payload.data.result.chargings;
      state.point_numbers = payload.data.result.points;
    },
    UPDATE_BILLING(state, payload) {
      state.billing_count = Number(payload.headers["total-count"]);
      state.billing = payload.data.result;
    },
    UPDATE_TARIFFS(state, payload) {
      state.tariffs_count = Number(payload.headers["total-count"]);
      state.tariffs = payload.data.result;
    },
    POINT_INFO(state, payload) {
      state.point_info = payload.info;
      state.events = payload.events;
      state.commands = payload.commands;
    },
    CHARGING_INFO(state, payload) {
      state.charging_info = payload.data.result;
    },
    CHARGING_HISTORY(state, payload) {
      state.history_count = Number(payload.headers["total-count"]);
      state.charging_history = payload.data.result;
    },
    CLEAR_CHARGING_INFO(state) {
      state.history_count = 0;
      state.charging_history = [];
      state.charging_info = {
        id: 0,
        transaction_id: "",
        remote_start_id: null,
        power_amount: 0.0,
        payment_amount: 0.0,
        charge_power: 0.0,
        current_battery: 0.0,
        remaining_time: 0.0,
        reserved_evse: 0,
        reserved_connector: 0,
        reserve_time: null,
        reserve_end: null,
        created_at: "26/01/2022 11:57:32",
        updated_at: "26/01/2022 11:57:08",
        status: "",
        charging_point_number: 0,
      };
    },
    POINT_TARIFFS(state, payload) {
      state.point_tariffs = payload;
    },
    LOADING_USERS(state, payload) {
      state.loading_users = payload;
    },
    LOADING_CLIENTS(state, payload) {
      state.loading_clients = payload;
    },
    LOADING_POINTS(state, payload) {
      state.loading_points = payload;
    },
    LOADING_CHARGES(state, payload) {
      state.loading_charges = payload;
    },
    LOADING_BILLING(state, payload) {
      state.loading_billing = payload;
    },
    LOADING_TARIFFS(state, payload) {
      state.loading_tariffs = payload;
    },
    SMS_LOADING(state, payload) {
      state.loading_sms = payload;
    },
    SMS_LIST(state, payload) {
      state.sms_count = Number(payload.headers["total-count"]);
      state.sms_list = payload.data.result.sms;
    },
    POINT_TARIFFS_LOADING(state, payload) {
      state.point_tariffs_loading = payload;
    },
    DASHBOARD_DATA(state, payload) {
      state.dashboard = payload;
    },
    INFO_LOADING(state, payload) {
      state.point_info_loading = payload;
    },
    CHARGING_INFO_LOADING(state, payload) {
      state.loading_charge_info = payload;
    },
    CHARGING_HISTORY_LOADING(state, payload) {
      state.loading_history_info = payload;
    },
    LATEST_DATA(state, payload) {
      state.latest_charges = payload;
    },
    LOADING_PARTNERS(state, payload) {
      state.partners_loading = payload;
    },
    UPDATE_PARTNERS(state, payload) {
      state.partners_count = Number(payload.headers["total-count"]);
      state.partners = payload.data.result;
    },
    COMPANY_DIALOG(state, payload) {
      state.company_dialog = payload;
    },
    SMS_DIALOG(state, payload) {
      state.sms_dialog = payload;
    },
    ADD_POINT_DIALOG(state, payload) {
      state.add_point_dialog = payload;
    },
    CLIENT_LOADING(state, payload) {
      state.client_loading = payload;
    },
    CLIENT_INFO(state, payload) {
      state.client_info = payload.result;
    },
    POINT_COMMAND_DIALOG(state, payload) {
      state.point_command_dialog = payload;
    },
    TRANSACTIONS(state, payload) {
      state.transactions = payload;
    },
    RFCARD_LOADING(state, payload) {
      state.loading_rfcard = payload;
    },
    RFCARD_LIST(state, payload) {
      state.rfcard_count = Number(payload.headers["total-count"]);
      state.rfcard_list = payload.data.result;
    },
    OPEN_INVITE_DIALOG(state, payload) {
      state.partner_invite_company = payload.company;
      state.partner_invite_dialog = payload.open;
    },
  },
  actions: {
    update_drawer(store, payload) {
      store.commit("UPDATE_DRAWER", payload);
    },
    list_points(store, options) {
      if (!("page" in options)) return;
      localStorage.setItem("point_filters", JSON.stringify(options));
      store.commit("LOADING_POINTS", true);
      const { sortBy, sortDesc, page, itemsPerPage, search, city, vendor, fw } =
        options;
      return axios
        .get("/srv/v1/points", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            search: search,
            city: city,
            vendor: vendor,
            fw: fw,
          },
        })
        .then((resposnse) => {
          store.commit("UPDATE_POINTS", resposnse);
        })
        .then(() => {
          store.commit("LOADING_POINTS", false);
        });
    },
    list_users(store) {
      store.commit("LOADING_USERS", true);
      return axios
        .get("/srv/v1/users")
        .then((response) => {
          store.commit("UPDATE_USERS", response.data.result);
        })
        .then(() => {
          store.commit("LOADING_USERS", false);
        });
    },
    list_clients(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_CLIENTS", true);
      localStorage.setItem("client_filters", JSON.stringify(options));
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
        phone,
        search,
        testers,
        ban,
        date,
      } = options;
      return axios
        .get("/srv/v1/clients", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            phone: phone,
            testers: testers,
            ban: ban,
            search: search,
            date: date,
          },
        })
        .then((response) => {
          store.commit("UPDATE_CLIENTS", response);
        })
        .then(() => {
          store.commit("LOADING_CLIENTS", false);
        });
    },
    list_charges(store, options) {
      if (!("page" in options)) return;
      localStorage.setItem("charges_filters", JSON.stringify(options));
      store.commit("LOADING_CHARGES", true);
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
        startDate,
        endDate,
        client,
        status,
        payment,
        rfcard,
        point,
      } = options;
      return axios
        .get("/srv/v1/charges", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            startDate: startDate,
            endDate: endDate,
            client: client,
            status: status,
            payment: payment,
            rfcard: rfcard,
            point: point,
          },
        })
        .then((response) => {
          store.commit("UPDATE_CHARGES", response);
        })
        .then(() => {
          store.commit("LOADING_CHARGES", false);
        });
    },
    list_billing(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_BILLING", true);
      const { sortBy, sortDesc, page, itemsPerPage, request_id } = options;
      return axios
        .get("/srv/v1/payment", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            query: request_id,
          },
        })
        .then((response) => {
          store.commit("UPDATE_BILLING", response);
        })
        .then(() => {
          store.commit("LOADING_BILLING", false);
        });
    },
    load_point_info(store, params) {
      localStorage.setItem("point_info", JSON.stringify(params));

      return axios
        .get(`/srv/v1/points/${params.point_id}/info`, {
          params: {
            ...params.query,
          },
        })
        .then((response) => {
          store.commit("POINT_INFO", response.data.result);
        });
    },
    info_loading(store, payload) {
      store.commit("INFO_LOADING", payload);
    },
    load_charging_info(store, charging_id) {
      store.commit("CHARGING_INFO_LOADING", true);
      return axios
        .get(`/srv/v1/charging/${charging_id}/info`)
        .then((response) => {
          store.commit("CHARGING_INFO", response);
        })
        .then(() => {
          store.commit("CHARGING_INFO_LOADING", false);
        });
    },
    load_charging_history(store, { charging_id, options }) {
      if (!("page" in options)) return;
      const { sortBy, sortDesc, page, itemsPerPage } = options;
      store.commit("CHARGING_HISTORY_LOADING", true);
      return axios
        .get(`/srv/v1/charging/${charging_id}/history`, {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
          },
        })
        .then((response) => {
          store.commit("CHARGING_HISTORY", response);
        })
        .then(() => {
          store.commit("CHARGING_HISTORY_LOADING", false);
        });
    },
    clear_charging_info(store) {
      store.commit("CLEAR_CHARGING_INFO");
    },
    load_point_tariffs(store, point_number) {
      store.commit("POINT_TARIFFS_LOADING", true);
      return axios
        .get(`/srv/v1/points/${point_number}/tariffs`)
        .then((response) => {
          store.commit("POINT_TARIFFS", response.data.result);
        })
        .then(() => {
          store.commit("POINT_TARIFFS_LOADING", false);
        });
    },
    load_tariffs(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_TARIFFS", true);
      const { sortBy, sortDesc, page, itemsPerPage, query } = options;
      return axios
        .get("/srv/v1/tariffs", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            query: query,
          },
        })
        .then((response) => {
          store.commit("UPDATE_TARIFFS", response);
        })
        .then(() => {
          store.commit("LOADING_TARIFFS", false);
        });
    },
    load_tariff(store, tariff_id) {
      store.commit("TARIFF_LOADING", true);
      return axios
        .get(`/srv/v1/tariff/${tariff_id}/info`)
        .then((response) => {
          store.commit("POINT_INFO", response.data.result);
        })
        .then(() => {
          store.commit("INFO_LOADING", false);
        });
    },
    add_point_tariff(store, tariff_id) {
      const point_number = store.getters.POINT_INFO.point_number;
      return axios.put(`/srv/v1/points/${point_number}/tariff/${tariff_id}`);
    },
    delete_point_tariff(store, tariff_id) {
      const point_number = store.getters.POINT_INFO.point_number;
      return axios.delete(`/srv/v1/points/${point_number}/tariff/${tariff_id}`);
    },
    create_user(store, user) {
      return axios.post("/srv/v1/user", user);
    },
    update_user(store, user) {
      return axios.put(`/srv/v1/user/${user["user_id"]}`, user);
    },
    check_point_number(store, point_number) {
      return axios.post(`/srv/v1/points/${point_number}/info`);
    },
    create_point(store, point) {
      return axios.post("/srv/v1/point", point);
    },
    enable_point(store, point_param) {
      return axios.post(`/srv/v1/point/${point_param["point_number"]}/enable`, {
        enable: point_param["enable"],
      });
    },
    update_point(store, point) {
      return axios.put(`/srv/v1/point/${point.point_number}`, point);
    },
    create_tariff(store, tariff) {
      return axios.post("/srv/v1/tariff", tariff);
    },
    update_tariff(store, tariff) {
      return axios.put(`/srv/v1/tariff/${tariff.id}`, tariff);
    },
    dashboard(store) {
      return axios.get("/srv/v1/dashboard").then((response) => {
        store.commit("DASHBOARD_DATA", response.data.result);
      });
    },
    latest_charges(store) {
      return axios.get("/srv/v1/latest").then((response) => {
        store.commit("LATEST_DATA", response.data.result);
      });
    },
    upload_point_image(store, point_data) {
      return axios.put(
        `/srv/v1/point/${point_data.point_number}/image`,
        point_data.form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }.then(() => {
          this.$store.dispatch("load_point_info", point_data.point_number);
        })
      );
    },
    list_partners(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_PARTNERS", true);
      const { sortBy, sortDesc, page, itemsPerPage, query } = options;
      return axios
        .get("/srv/v1/partners", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            query: query,
          },
        })
        .then((response) => {
          store.commit("UPDATE_PARTNERS", response);
        })
        .then(() => {
          store.commit("LOADING_PARTNERS", false);
        });
    },
    create_company_dialog(store, payload) {
      store.commit("COMPANY_DIALOG", payload);
    },
    create_company(store, params) {
      return axios.post("/srv/v1/partner", params);
    },
    send_sms_dialog(store, payload) {
      store.commit("SMS_DIALOG", payload);
    },
    send_sms(store, payload) {
      return axios.post("/srv/v1/send_sms", payload);
    },
    list_sms(store, options) {
      store.commit("SMS_LOADING", true);
      const { sortBy, sortDesc, page, itemsPerPage, query } = options;
      return axios
        .get(`/srv/v1/sms`, {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            query: query,
          },
        })
        .then((response) => {
          store.commit("SMS_LIST", response);
        })
        .then(() => {
          store.commit("SMS_LOADING", false);
        });
    },
    add_point_dialog(store, payload) {
      store.commit("ADD_POINT_DIALOG", payload);
    },
    client_info(store, client_id) {
      store.commit("CLIENT_LOADING", true);
      return axios
        .get(`/srv/v1/client/${client_id}`)
        .then((response) => {
          store.commit("CLIENT_INFO", response.data);
        })
        .then(() => {
          store.commit("CLIENT_LOADING", false);
        });
    },
    point_command_dialog(store, payload) {
      store.commit("POINT_COMMAND_DIALOG", payload);
    },
    send_point_command(store, payload) {
      const point_number = store.getters.POINT_INFO.point_number;
      return axios.post(`/srv/v1/point/${point_number}/command`, payload);
    },
    show_point(store, payload) {
      return axios.put(`/srv/v1/point/${payload.point_number}/show`, {
        show: payload.show,
      });
    },
    load_transaction(store, payload) {
      return axios
        .post(`/srv/v1/point/${payload.point_number}/transactions`)
        .then((response) => {
          store.commit("TRANSACTIONS", response.data.result);
        });
    },
    update_tester(store, payload) {
      const { client, is_tester } = payload;
      return axios.put(`/srv/v1/client/${client}`, { is_tester: is_tester });
    },
    update_ending(store, payload) {
      const { client, ending_80 } = payload;
      return axios.put(`/srv/v1/client/${client}`, { ending_80: ending_80 });
    },
    update_ban(store, payload) {
      const { client, ban } = payload;
      return axios.put(`/srv/v1/client/${client}`, { ban: ban });
    },
    card_ban(store, payload) {
      const { card, ban } = payload;
      return axios.put(`/srv/v1/client/card/${card}`, { ban: ban });
    },
    save_comment(store, payload) {
      const { comment, client_id } = payload;
      return axios.put(`/srv/v1/client/${client_id}/comment`, {
        comment: comment,
      });
    },
    stop_charging(store, charge_id) {
      return axios.put(`/srv/v1/charging/${charge_id}/stop`);
    },
    payment_charging(store, charge_id) {
      return axios.post(`/srv/v1/payment/${charge_id}`);
    },
    list_rfcard(store, options) {
      store.commit("RFCARD_LOADING", true);
      const { sortBy, sortDesc, page, itemsPerPage, query } = options;
      return axios
        .get("/srv/v1/rfcards", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            query: query,
          },
        })
        .then((response) => {
          store.commit("RFCARD_LIST", response);
        })
        .then(() => {
          store.commit("RFCARD_LOADING", false);
        });
    },
    create_rfcard(store, rfcard) {
      return axios.post("/srv/v1/rfcard", rfcard);
    },
    update_rfcard(store, rfcard) {
      const rfcard_id = rfcard.id;
      delete rfcard.id;
      return axios.put(`/srv/v1/rfcard/${rfcard_id}`, rfcard);
    },
    remove_point_tariff(store, params) {
      const { point_number, tariff_id } = params;
      return axios.delete(`/srv/v1/points/${point_number}/tariff/${tariff_id}`);
    },
    open_invite_dialog(store, payload) {
      store.commit("OPEN_INVITE_DIALOG", payload);
    },
    send_partner_invite(store, payload) {
      return axios
        .post(
          `/srv/v1/partner/${store.state.partner_invite_company}/invite`,
          payload
        )
        .then(() => {
          store.commit("OPEN_INVITE_DIALOG", { open: false, company: null });
        })
        .then(() => {
          store.dispatch("list_partners", {
            sortBy: ["id"],
            sortDesc: [false],
            page: 1,
          });
        });
    },
    create_payment_report(store, params) {
      return axios.post("/srv/v1/payment_report", params);
    },
    create_charging_report(store, params) {
      return axios.post("/srv/v1/charging_report", params);
    },
  },
  modules: {},
  getters: {
    LOCALE(state) {
      return state.locale;
    },
    DRAWER(state) {
      return state.drawer;
    },
    POINTS(state) {
      return state.points;
    },
    CITY_FILTER(state) {
      return state.city;
    },
    VENDOR_FILTER(state) {
      return state.vendor;
    },
    FW_FILTER(state) {
      return state.fw;
    },
    USERS(state) {
      return state.users;
    },
    CLIENTS(state) {
      return state.clients;
    },
    CHARGES(state) {
      return state.charges;
    },
    BILLING(state) {
      return state.billing;
    },
    TARIFFS(state) {
      return state.tariffs;
    },
    LOADING_USERS(state) {
      return state.loading_users;
    },
    LOADING_CLIENTS(state) {
      return state.loading_clients;
    },
    TOTAL_CLIENTS(state) {
      return state.clients_count;
    },
    LOADING_CHARGES(state) {
      return state.loading_charges;
    },
    LOADING_CHARGE_INFO(state) {
      return state.loading_charge_info;
    },
    TOTAL_CHARGES(state) {
      return state.charges_count;
    },
    LOADING_POINTS(state) {
      return state.loading_points;
    },
    LOADING_BILLING(state) {
      return state.loading_billing;
    },
    LOADING_TARIFFS(state) {
      return state.loading_tariffs;
    },
    POINT_TARIFFS_LOADING(state) {
      return state.point_tariffs_loading;
    },
    TOTAL_POINTS(state) {
      return state.points_count;
    },
    TOTAL_BILLING(state) {
      return state.billing_count;
    },
    TOTAL_TARIFFS(state) {
      return state.tariffs_count;
    },
    DASHBOARD(state) {
      return state.dashboard;
    },
    LATEST_CHARGES(state) {
      return state.latest_charges;
    },
    POINT_INFO(state) {
      return state.point_info;
    },
    INFO_LOADING(state) {
      return state.point_info_loading;
    },
    POINT_TARIFFS(state) {
      return state.point_tariffs;
    },
    CHARGING_INFO(state) {
      return state.charging_info;
    },
    CHARGING_HISTORY(state) {
      return state.charging_history;
    },
    HISTORY_LOADING(state) {
      return state.loading_history_info;
    },
    PARTNERS_LOADING(state) {
      return state.partners_loading;
    },
    PARTNERS_COUNT(state) {
      return state.partners_count;
    },
    PARTNERS(state) {
      return state.partners;
    },
    COMPANY_DIALOG(state) {
      return state.company_dialog;
    },
    AVAILABLE_EVENTS(state) {
      return state.events;
    },
    AVAILABLE_COMMANDS(state) {
      return state.commands;
    },
    SMS(state) {
      return state.sms_list;
    },
    SMS_COUNT(state) {
      return state.sms_count;
    },
    SMS_LOADING(state) {
      return state.loading_sms;
    },
    SMS_DIALOG(state) {
      return state.sms_dialog;
    },
    ADD_POINT_DIALOG(state) {
      return state.add_point_dialog;
    },
    CLIENT_LOADING(state) {
      return state.client_loading;
    },
    CLIENT_INFO(state) {
      return state.client_info;
    },
    POINT_COMMAND_DIALOG(state) {
      return state.point_command_dialog;
    },
    TRANSACTIONS(state) {
      return state.transactions;
    },
    RFCARDS(state) {
      return state.rfcard_list;
    },
    RFCARD_LOADING(state) {
      return state.loading_rfcard;
    },
    HISTORY_COUNT(state) {
      return state.history_count;
    },
    INVITE_DIALOG(state) {
      return state.partner_invite_dialog;
    },
    POINT_NUMBERS(state) {
      return state.point_numbers;
    },
  },
});
