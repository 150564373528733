import Vue from "vue";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/chartist";
import i18n from "@/localization";

// import './plugins/gmap'
import "./assets/style.scss";

import http from "@/http";
import auth from "@/auth";

import DefaultLayout from "./layouts/default.vue";
import LoginLayout from "./layouts/login.vue";

Vue.component("default-layout", DefaultLayout);
Vue.component("login-layout", LoginLayout);

Vue.config.productionTip = false;
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
import rawDisplayer from "./views/tariff/raw_displayer.vue";
Vue.component("rawDisplayer", rawDisplayer);
import moment from "moment";
Vue.prototype.$moment = moment;

// Sentry.init({
//   Vue,
//   dsn: "http://e1e9d0ea67c2494f8abf663a2e85531a@192.168.88.84:9000/4",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "127.0.0.1", /^\//],
//     }),
//   ],
// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for performance monitoring.
// We recommend adjusting this value in production
// tracesSampleRate: 1.0,
// environment: "develop",
// release: "0.0.1",
// });

new Vue({
  router,
  store,
  vuetify,
  http,
  auth,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
