<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const DEFAULT_LAYOUT = "login";

export default {
  name: "App",
  created() {
    if(localStorage.getItem('darkMode')){
      this.$vuetify.theme.dark = Boolean(localStorage.getItem('darkMode'))
    }
  },
  components: {},

  computed: {
    layout() {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + "-layout";
    },
  },
  methods: {},
};
</script>

<style></style>
