export default {
  rulesText: {
    required: "Required",
    noSpace: "Without space",
    min15: "Min. 15 characters",
    max255: "Max. 255 characters",
    onlyNumber: "Only number",
    isIp: "It's not IP",
    isMac: "It's not MAC",
    minOneDns: "Enter at least one DNS",
    isMask: "It's not mask",
    from_min_to_max: "From {0} to {1}",
  },

  navBar: {
    index: "Index",
    points: "Charge points",
    users: "Users",
    billing: "Billing",
    editDevice: "Edit charge point",
    history: "History",
    point_config: "Charge point configure",
    logout: "Logout",
  },

  loginPage: {
    deviceName: "CP management",
    username: "Username",
    password: "Password",
    error401: "Incorrect username or password",
    login: "Login",
    alert: "Incorrect username or password",
  },

  networkPage: {
    lan1: "Lan1",
    lan2: "Lan2",
    inactive: "This interface is currently inactive",
    saveButton: "Save",
    ipConfig: {
      mode: "Work mode",
      modeStatic: "Static IP",
      modeDHCP: "DHCP",
      ip: "IP address",
      ipHint: "192.168.1.1",
      mask: "Netmask",
      maskHint: "255.255.255.0",
      gateway: "Gateway",
      gatewayHint: "192.168.1.1",
      dns1: "DNS 1",
      dns1Hint: "192.168.1.1",
      dns2: "DNS 2",
      dns2Hint: "192.168.1.1",
    },
  },

  devicesPage: {
    slaveID: "Slave ID",
    mqtt: "MQTT topic",
    protocol: "Protocol",
    interval: "Poll interval (ms)",
    port: "Port",
    history: "History",
    edit: "Edit",
    addButton: "Add device",
  },

  addDevicePage: {
    name: "Add device",
    port: "Port",
    portList: [
      "RS-485 #1",
      "RS-485 #2",
      "RS-232 #1",
      "RS-232 #2",
      "LAN1",
      "LAN2",
      "1-Wire",
    ],
    deviceType: "Device",
    deviceCustom: "Custom",
    slaveID: "Slave ID",
    slaveIDHint: "Decimal (e.g. 123)",
    mqttName: "MQTT name",
    mqttNameHint: "E.g. device1",
    password: "Password",
    passwordHint: "Enter password",
    registers: "Registers",
    selectAll: "Select all",
    deselectAll: "Deselect all",
    interval: "Poll interval (ms)",
    intervalHint: "Enter poll interval",
    history: "Save device history",
    historyList: [
      "Disabled",
      "1 min",
      "5 min",
      "10 min",
      "15 min",
      "30 min",
      "1 h",
      "6 h",
      "12 h",
      "24 h",
    ],
    cancelButton: "Cancel",
    saveButton: "Add device",
    alert: "Check that the input is correct",
  },

  editDevicePage: {
    name: "Edit device",
    saveButton: "Edit device",
    deleteButton: "Delete device",
    confirm: {
      title: "Do you really want to delete the device?",
      cancelButton: "Cancel",
      confirmButton: "Delete",
    },
  },

  historyPage: {
    device: "Device",
    channel: "Channel",
    period: "Period",
    setDate: "OK",
    cancelSetDate: "Cancel",
    export: "Export CSV",
    loading: "Loading",
    chart: {
      title: "Device history",
      yAxisTitle: "Values",
      xAxisTitle: "Date",
    },
    intervals: {
      m_1: "Last minute",
      m_10: "Last 10 minutes",
      m_30: "Last 30 minutes",
      h_1: "Last hour",
      h_3: "Last 3 hours",
      h_5: "Last 5 hours",
      h_12: "Last 12 hours",
      h_24: "Last 24 hours",
      d_3: "Last 3 days",
      d_7: "Last 7 days",
      custom: "Custom",
    },
  },

  serialPortConfig: {
    inactive: "This port is currently inactive",
    ports: ["RS-485 #1", "RS-485 #2", "RS-232 #1", "RS-232 #2"],
    baud: "Baud rate",
    baudList: [
      "1200",
      "2400",
      "4800",
      "9600",
      "19200",
      "38400",
      "57600",
      "115200",
    ],
    databit: "Data bits",
    databitList: ["7", "8"],
    parity: "Parity",
    parityList: ["None", "Even", "Odd"],
    stopbit: "Stop bits",
    stopbitList: ["1", "2"],
    saveButton: "Save",
  },

  registersPage: {
    device: "Device",
    showTable: "Show table",
    hideTable: "Hide table",
    name: "Register",
    value: "Value",
    newValue: "New value",
    showChart: "Show chart",
    add_chart: "Add new chart",
    chart: {
      none: "None",
      title: "Chart",
    },
  },

  servoPage: {
    value: "Value",
    step: "Step",
  },

  charts: {
    zoom: "Zoom",
    m_10: "10m",
    h_1: "1h",
    h_3: "3h",
    h_6: "6h",
    h_24: "24h",
    all: "All",
  },
};
