<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <the-nav-drawer />
    <the-nav-bar />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import theNavBar from "@/components/NavBar/theNavBar";
import theNavDrawer from "@/components/NavBar/theNavDrawer";

export default {
  name: "default",

  components: {
    theNavBar,
    theNavDrawer,
  },

  data: () => ({}),

  created() {},
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
