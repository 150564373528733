import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      themeCache: {
        get: (key) => {
          localStorage.getItem(key);
        },
        set: (key, value) => {
          localStorage.setItem(key, value);
        },
      },
    },
    themes: {
      light: {
        primary: "#1976D2",
        accent: "#82B1FF",
        secondary: "#424242",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FFC107",
        error: "#FF5252",
      },
      dark: {
        primary: "#33691E",
        accent: "#FFCA28",
        secondary: "#607D8B",
        success: "#FFEB3B",
        info: "#2196F3",
        warning: "#9C27B0",
        error: "#B71C1C",
      },
    },
  },
});
