import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

export const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://192.168.88.119:10000/"
    : //"https://rc.ruscharge.ru/"
      "";

axios.defaults.baseURL = baseUrl;
Vue.use(VueAxios, axios);

export default {
  root: process.env.VUE_APP_API_URL,
};
